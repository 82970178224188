import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { showAlert } from '../../utils';

const csrfTag = document.querySelector('meta[name=csrf-token]');
if (csrfTag) {
  const csrfToken = csrfTag.content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

export default class extends Controller {
  static targets = ['phone', 'countryCode', 'form']

  // eslint-disable-next-line class-methods-use-this
  async send() {
    let phone = `${this.countryCodeTarget.value}${this.phoneTarget.value}`;
    phone = phone.replace(/[^+0-9]/g, '');
    if (!phone.match(/^\+[1-9]\d{1,14}$/)) {
      showAlert('error', 'Phone number is invalid, please verify your input. It should start with country code.');
      return;
    }
    try {
      await axios.post(this.element.dataset.url, { phone });
      this.application.getControllerForElementAndIdentifier(this.element, 'modal').hide();
      this.formTarget.reset();
      showAlert('success', 'An SMS has been sent successfully.');
    } catch {
      showAlert('error', 'An error occured. Please verify your input and try again. If this error persists, please contact your event organizer.');
    }
  }
}
