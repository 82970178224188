import jsonFormData from 'json-form-data';
import api from './api';

export async function index(params = {}) {
  try {
    const response = await api.get('images', { params });
    return response;
  } catch (e) {
    return {
      data: [],
      headers: {
        paginationcount: 0,
      },
    };
  }
}

export async function save(image) {
  const formData = jsonFormData({ image }, { showLeafArrayIndexes: false });
  try {
    const { data } = await api.post('images', formData);
    return { data };
  } catch (error) {
    return { error };
  }
}

export function destroy(id) {
  api.delete(`images/${id}`);
}
