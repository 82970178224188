import { Controller } from '@hotwired/stimulus';
import ImagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';

export default class extends Controller {
  connect() {
    ImagesLoaded(this.element, () => {
      // eslint-disable-next-line no-new
      new Isotope(this.element, {
        itemSelector: '.item',
        layoutMode: 'masonry',
      });
    });
  }
}
