import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.querySelectorAll(`a[data-modal-target=${this.element.id}]`).forEach((el) => {
      el.addEventListener('click', this.show.bind(this));
    });
  }

  show() {
    this.element.style.display = 'flex';
  }

  hide() {
    this.element.style.display = 'none';
  }

  // eslint-disable-next-line class-methods-use-this
  stop(event) {
    event.stopPropagation();
  }
}
