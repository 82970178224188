import { Controller } from '@hotwired/stimulus';

import { save } from '../../gateways/images.js';

export default class extends Controller {
  connect() {
    window.tinymce.init({
      target: this.element,
      menubar: false,
      inline: true,
      plugins: [
        'link', 'textcolor', 'lists', 'contextmenu', 'autolink', 'template', 'image', 'code', 'visualblocks', 'media',
      ],
      style_formats: [
        {
          title: 'Image — Left Float',
          selector: 'img',
          styles: {
            float: 'left',
            'margin-right': '5px',
          },
        },
        {
          title: 'Image — Right Float',
          selector: 'img',
          styles: {
            float: 'right',
            'margin-left': '5px',
          },
        },
        {
          title: 'Image — 100% Wide',
          selector: 'img',
          styles: {
            width: '100%',
            height: 'auto',
          },
        },
        {
          title: 'Image — Max. 100% Wide',
          selector: 'img',
          styles: {
            'max-width': '100%',
            height: 'auto',
          },
        },
      ],
      toolbar: [
        [
          'fontselect', 'fontsizeselect', 'styleselect', '|',
          'bold', 'italic', '|',
          'alignleft', 'aligncenter', 'alignright', 'alignjustify', '|',
          'undo', 'redo',
        ].join(','),
        [
          'bullist', 'numlist', '|',
          'outdent', 'indent', '|',
          'link', 'image', 'template', 'media', '|',
          'code', 'visualblocks', '|',
          'forecolor',
        ].join(','),
      ],
      link_class_list: [
        { title: 'None', value: '' },
        { title: 'Google Analytics Track Outbound Link', value: 'ga-track' },
      ],
      templates: [
        {
          title: 'Row 1:1',
          description: 'Row containing two columns, each 50% wide. They stack for mobile devices',
          content: `
          <div class="flex">
            <div class="md:w-1/2">Column 1</div>
            <div class="md:w-1/2">Column 2</div>
          </div>`,
        },
        {
          title: 'Row 2:1',
          description: 'Row containing two columns, 66% and 33% wide. They stack for mobile devices',
          content: `
          <div class="flex">
            <div class="md:w-2/3">Column 1</div>
            <div class="md:w-1/3">Column 2</div>
          </div>`,
        },
        {
          title: 'Row 1:2',
          description: 'Row containing two columns, 33% and 66% wide. They stack for mobile devices',
          content: `
          <div class="flex">
            <div class="md:w-1/3">Column 1</div>
            <div class="md:w-2/3">Column 2</div>
          </div>`,
        },
      ],
      valid_children: '+body[style]',
      extended_valid_elements: 'style',
      custom_elements: 'style',
      fontsize_formats: 'XS=.75rem SM=.875rem MD=1rem L=1.125rem XL=1.25rem 2XL=1.5rem 3XL=1.875rem 4XL=2.25rem 5XL=3rem 6XL=4rem',
      async images_upload_handler(blobInfo, success, failure) {
        const { data, error } = await save({ file: blobInfo.blob() });
        if (error) {
          failure('Something went wrong, please try again');
        } else {
          success(data.file_url);
        }
      },
    });
  }
}
