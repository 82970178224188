import {
  fromMaybe, take, drop, append, isJust, justs, reduce,
} from 'sanctuary';

export function showAlert(kind, text, time = 3000) {
  const wrapper = document.querySelector('.alert-wrapper');
  const alert = document.createElement('div');
  alert.classList.add('alert');
  alert.classList.add(`alert-${kind}`);
  alert.setAttribute('data-controller', 'alert');
  alert.setAttribute('data-time', time);
  alert.innerHTML = `<i class="i-font"></i><div class="content">${text}</div>`;
  wrapper.appendChild(alert);
}

export function id(obj) {
  return obj;
}

export const chunkArray = (n) => (foldable) => {
  let reducible = foldable;
  let chunk = take (n) (reducible);
  let result = [];
  while (isJust(chunk)) {
    reducible = fromMaybe ([]) (drop (n) (reducible));
    result = append (chunk) (result);
    chunk = take (n) (reducible);
  }
  return justs (result);
};

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

export const arrayToIndexObject = reduce(
  (acc) => (item) => ({ ...acc, [Object.keys(acc).length]: item }),
) ({});
