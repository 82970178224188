import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { showAlert } from '../../utils';

const csrfTag = document.querySelector('meta[name=csrf-token]');
if (csrfTag) {
  const csrfToken = csrfTag.content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

export default class extends Controller {
  static targets = ['email', 'form']

  // eslint-disable-next-line class-methods-use-this
  async send() {
    const email = this.emailTarget.value;
    try {
      await axios.post(this.element.dataset.url, {
        sharing: { emails: [email] },
      });
      this.application.getControllerForElementAndIdentifier(this.element, 'modal').hide();
      this.formTarget.reset();
      showAlert('success', 'An email will be delivered soon');
    } catch {
      showAlert('error', 'An error occured. Please verify your input and try again.');
    }
  }
}
