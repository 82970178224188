import { Application } from '@hotwired/stimulus';
import '@hotwired/turbo-rails';

import AlertController from './src/controllers/alert_controller';
import ModalController from './src/controllers/modal_controller';
import EditorController from './src/controllers/microsites/editor_controller';
import SharingController from './src/controllers/microsites/sharing_controller';
import DigitalDeliveryController from './src/controllers/microsites/digital_delivery_controller';
import IsotopeController from './src/controllers/microsites/isotope_controller';
import PersistenceController from './src/controllers/microsites/persistence_controller';
import SmsDeliveryController from './src/controllers/microsites/sms_delivery_controller';

const application = Application.start();

application.register('alert', AlertController);
application.register('editor', EditorController);
application.register('modal', ModalController);
application.register('sharing', SharingController);
application.register('digital-delivery', DigitalDeliveryController);
application.register('isotope', IsotopeController);
application.register('persistence', PersistenceController);
application.register('sms-delivery', SmsDeliveryController);
