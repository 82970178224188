import api from './api';

export async function show(id) {
  try {
    const response = await api.get(`microsite_templates/${id}`);
    return response;
  } catch (e) {
    return {
      data: null,
    };
  }
}

export async function saveDesign(template) {
  try {
    const { data } = await api.patch(`microsite_templates/${template.id}`, { microsite_template: template });
    return { data };
  } catch (error) {
    return { error };
  }
}
