import { Controller } from '@hotwired/stimulus';

import { showAlert } from '../../utils';
import { saveDesign } from '../../gateways/microsite_templates';

export default class extends Controller {
  static targets = ['header', 'footer'];

  async save() {
    const { error } = await saveDesign({
      id: this.element.dataset.id,
      header_text: this.headerTarget.innerHTML,
      footer_text: this.footerTarget.innerHTML,
    });
    if (error) {
      showAlert('error', 'Something went wrong, please try again');
    } else {
      this.headerEditor().save();
      this.footerEditor().save();
      showAlert('success', 'Template has been saved successfully');
    }
  }

  async saveAndClose() {
    const { error } = await saveDesign({
      id: this.element.dataset.id,
      header_text: this.headerTarget.innerHTML,
      footer_text: this.footerTarget.innerHTML,
    });
    if (error) {
      showAlert('error', 'Something went wrong, please try again');
    } else {
      window.parent.postMessage('closeDesigner', '*');
    }
  }

  headerEditor() {
    return window.tinyMCE.get(this.headerTarget.id);
  }

  footerEditor() {
    return window.tinyMCE.get(this.footerTarget.id);
  }

  async close() {
    if (
      this.headerEditor().isDirty()
        || this.footerEditor().isDirty()
    ) {
      if (!window.confirm('You have unsaved changes. Are you sure you want to close editor?')) return;
    }
    window.parent.postMessage('closeDesigner', '*');
  }
}
