/* eslint-disable class-methods-use-this */

import axios from 'axios';
import { Controller } from '@hotwired/stimulus';

function getFileName(response) {
  return response.headers.get('Content-Disposition').split('filename="')[1].split('";')[0];
}

function showDownloadError() {
  alert('Error saving. Right-click or long-press media to download the file.');
}

async function downloadFile(href) {
  document.getElementById('loading').classList.remove('hidden');
  let file;
  try {
    const response = await fetch(href);
    const blob = await response.blob();
    const fileName = getFileName(response);
    file = new File([blob], fileName, { type: response.headers.get('Content-Type') });
  } catch (e) {
    console.error(e);
    showDownloadError();
  } finally {
    document.getElementById('loading').classList.add('hidden');
  }
  return file;
}

export default class extends Controller {
  facebook(ev) {
    if (!window.FB) return true;
    ev.preventDefault();

    const { currentTarget: { dataset }, currentTarget } = ev;
    FB.ui({
      method: 'share',
      href: dataset.url,
      display: 'popup',
    }, () => {
      window.ga('set', 'metric2', 1);
      window.ga('send', 'event', 'Facebook', 'Share');
      window.ga('set', 'metric2', 0);
    });
    this.track({ currentTarget });
    return false;
  }

  twitter() {
    window.ga('set', 'metric1', 1);
    window.ga('send', 'event', 'Twitter', 'Share');
    window.ga('set', 'metric1', 0);
  }

  track({ currentTarget: { dataset: { trackService } } }) {
    axios.post(this.element.dataset.apiTracksUrl, {
      track: {
        from_microsite: true,
        service: trackService,
        event_id: this.element.dataset.eventId,
      },
    });
    if (window.mixpanel) {
      window.mixpanel.track('Sharing', {
        Platform: 'Microsite',
        Type: trackService,
        EventID: this.element.dataset.eventId,
        PhotoID: this.element.dataset.photoId,
        EventPublished: this.element.dataset.eventPublished,
      });
    }
  }

  connect() {
    if (!window.FB) return;

    window.FB.Event.subscribe('comment.create', () => {
      window.ga('set', 'metric3', 1);
      window.ga('send', 'event', 'Facebook', 'Comment Added');
      return window.ga('set', 'metric3', 0);
    });

    window.FB.Event.subscribe('comment.remove', () => {
      window.ga('set', 'metric3', -1);
      window.ga('send', 'event', 'Facebook', 'Comment Removed');
      return window.ga('set', 'metric3', 0);
    });
  }

  download(e) {
    if (navigator.share) {
      e.preventDefault();
      (async () => {
        const file = await downloadFile(e.currentTarget.href);
        if (!file) return;

        if (navigator.userActivation && navigator.userActivation.isActive) {
          if (navigator.canShare({ files: [file] })) {
            navigator.share({ files: [file] });
          } else {
            showDownloadError();
          }
        } else {
          document.getElementById('download-ready').addEventListener('click', () => {
            if (navigator.canShare({ files: [file] })) {
              navigator.share({ files: [file] });
            } else {
              showDownloadError();
            }
            document.getElementById('download-ready').classList.add('hidden');
          }, { once: true });
          document.getElementById('download-ready').classList.remove('hidden');
        }
      })();
    }
  }
}

/* eslint-enable class-methods-use-this */
